/* latin */
$bevan-font-path: "../assets/fonts/bevan-webfont" !default;
@font-face {
  font-family: 'bevanregular';
  src: url('../assets/fonts/bevan-webfont.woff2') format('woff2'),
       url('../assets/fonts/bevan-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
